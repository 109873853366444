<template>
  <div class="columns is-centered">
    <div class="column is-two-fifths">
      <br>
      <form @submit.prevent="recoverPassword">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Récupération de mot de passe</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Adresse mail">
              <b-input type="email" v-model="form.email" placeholder="Votre adresse mail" maxlength="50" :has-counter="false" required>
              </b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button
              class="is-pulled-right"
              label="Confirmer"
              native-type="submit"
              type="is-success submit"/>
          </footer>
          <b-progress type="is-info" v-if="sending"></b-progress>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import notification from "@/mixins/notification";

export default {
  name: 'Recover',
  data: () => ({
    user: {},
    form: {
      email: null
    },
    sending: false
  }),
  mixins: [notification],
  methods: {
    recoverPassword() {
      this.sending = true;
      const data = {
        email: this.form.email,
      };
        this.$store.dispatch('recover', data)
          .then(() => {
            this.showNotification('Demande de récupération envoyée', 'success');
            router.push('/');
          })
          .catch(() => {
            this.showError('La demande de récupération n\'a pas pu être faite.')
            this.sending = false;
          });
    }
  }
};
</script>

